import React, { useState, useEffect } from 'react'
import notificacion from './../../../Imagenes/Iconos/notificacion.png'
import usuario from './../../../Imagenes/Iconos/usuario.png'
import flechaOpciones from './../../../Imagenes/Iconos/flechaOpciones.png'
import LogoSanMiguel from './../../../Imagenes/LogoSanMiguel.png'
import { cerrarSesion,validarSesion, obtenerCookie} from '../../../Api/Api'
import { useNavigate } from 'react-router-dom';

import './BarraSuperior.css'
import '../EstilosGenerales/EstilosGenerales.css'

function BarraSuperior () {
  const [estaProcesando, setestaProcesando] = useState(false)
  const [esComputadora, setesComputadora] = useState(
    window.innerWidth > window.innerHeight
  )
  const [isHovered, setIsHovered] = useState(false)
  const [idHovered, setIdHovered] = useState('')
  const [diferenciaDimensiones, setDifDim] = useState(
    window.innerWidth - window.innerHeight
  )
  const [mostrarOpcionesUsuario,setMostrarOpcionesUsuario] = useState(null)
  const navigate=useNavigate()
  const [hayError, setHayError] = useState(false)
  const [errores,seterrores] = useState(null)

  useEffect(() => {
    const validar = async () => {
      const respuesta = await validarSesion(); // Esta función debe retornar el resultado que necesitas para la validación
      if(respuesta.SMListaErrores.length !==0){
        navigate('/')
      }
    }
    validar();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setesComputadora(window.innerWidth > window.innerHeight)
      if(window.innerWidth > window.innerHeight && (window.innerWidth > window.innerHeight)>0){
        setDifDim(window.innerWidth - window.innerHeight)
      
      }
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function hexToRGBA (hex, opacidad) {
    hex = hex.replace('#', '')
    const r = parseInt(hex.substring(0, 2), 16)
    const g = parseInt(hex.substring(2, 4), 16)
    const b = parseInt(hex.substring(4, 6), 16)

    return `rgba(${r}, ${g}, ${b}, ${opacidad})`
  }
  const manejadorBotonClick = async event => {}
  const contenedorBarraSuperiorStyle = {
    display: 'grid',
    gridTemplateColumns: esComputadora ? '15% 25% 25% 5% 20% 5% 5%': '30% 10% 10% 10% 20% 10% 10%',
    gridTemplateRows: '100% 30px',
    boxSizing: 'content-box',
    //backgroundColor: "red",
    width: '100%',
    height: '10vh'
  }
  const propiedadesAdicionalesIconos = {
    display: 'inline-block',
    width: esComputadora ? '100%' : '100%',
    height: esComputadora ? '100%' : '100%',
    textAlign: 'center',
    boxSizing: 'border-box',
    height: esComputadora ? 'auto' : 'auto'
  }
  const estiloHover = {
    ...(isHovered && {
      cursor: 'pointer',
      ...(!esComputadora && { borderBottom: '5px solid #603711' }),
      ...(esComputadora && { borderLeft: '5px solid #603711' })
    })
  }
  const cotenidoLogostyle = {
    gridArea: esComputadora ? '1 / 1 / 2 / 2' : '1 / 1 / 2 / 2',
    justifySelf: "center",
    alignSelf: "center",
    ...propiedadesAdicionalesIconos
  }

  const contenidoFotoUsuariostyle = {
    display: "flex",
    gridArea: esComputadora ? '1 / 6 / 2 / 7' : '1 / 6 / 2 / 7',
    justifySelf: "center",
    alignSelf: "center",
    ...propiedadesAdicionalesIconos,
    ...(idHovered === 'pedidos' ? estiloHover : ''),
  }
  const fondoAuxFotostyle={
    backgroundColor: "white",
    borderRadius: "100%",
    width: "90%",
    boxSizing: "border-box"
  }
  const contenidoNotificacionstyle = {
    display: "none",
    gridArea: esComputadora ? '1 / 4 / 2 / 5' : '1 / 4 / 2 / 5',
    justifySelf: "center",
    alignSelf: "center",
    ...propiedadesAdicionalesIconos,
    ...(idHovered === 'generarPedido' ? estiloHover : '')
  }
  const cotenidoInfoUsuariostyle = {
    gridArea: esComputadora ? '1 / 5 / 2 / 6' : '1 / 5 / 2 / 6',
    justifySelf: "center",
    alignSelf: "center",
    marginRight: "20px",
    ...propiedadesAdicionalesIconos,
    ...(idHovered === 'despacho' ? estiloHover : '')
  }
  const contenidoOpcionesUsuariostyle = {
    gridArea: esComputadora ? '1 / 7 / 2 / 8' : '1 / 7 / 2 / 8',
    justifySelf: "center",
    alignSelf: "center",
    ...propiedadesAdicionalesIconos,
    ...(idHovered === 'despacho' ? estiloHover : '')
  }

  const iconoLogoSanMiguelStyle = {
    width: esComputadora ? '72%' : '100%',
    //height: '10vh',
    boxSizing: 'border-box',
    padding: esComputadora ? '10px 10px 10px 10px' : '5px'
  }
  const icononotificacionstyle={
    display: "none",
    width: esComputadora ? '60%' : '60%',
    height: '',
    boxSizing: 'border-box',
    padding: esComputadora ? diferenciaDimensiones<350 ? '1px':'10px 10px 10px 10px' : '5px'
  }
  const iconosusuariotyle={
    verticalAlign: "middle",
    filter: "invert(100%) brightness(80%) contrast(80%)",
    width: esComputadora ? '100%' : '100%',
    height: '',
    boxSizing: 'border-box',
    padding: esComputadora ? diferenciaDimensiones<300 ? '1px':'0px' : '5px'
  }
  const iconoflechaOpcionesstyle={
    //filter: "",
    filter: "invert(100%) brightness(100%) hue-rotate(0deg) drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.7))",
    marginLeft: "-70%",
    width: esComputadora ? '50%' : '70%',
    height: '',
    boxSizing: 'border-box',
    padding: esComputadora ? diferenciaDimensiones<300 ? '1px':'0px' : '5px',
    transition: "transform 0.3s ease",
    ...(isHovered && {
      cursor: 'pointer',
      //backgroundColor: hexToRGBA('#DCAD2F', 1.0),
      //boxShadow: 'inset 0 0 22px #FFFFFF',
      //...(!esComputadora && { borderBottom: '5px solid #603711' }),
      //...(esComputadora && { borderLeft: '5px solid #603711' })
    }),
    ...(mostrarOpcionesUsuario && {
      transform: "rotate(-90deg)"
    })
  }
  const nombreStyle = {
    fontWeight: '500',
    fontSize: esComputadora ? '1vw' : '2vw',
    margin: '0px',
    padding: '0px',
    lineHeight: '0.9',
    textAlign: "right"
  }
  const rolStyle = {
    fontWeight: '400',
    fontSize: esComputadora ? '1vw' : '2vw',
    marginTop: '5px',
    padding: '0px',
    lineHeight: '0.9',
    textAlign: "right",
    color: "#666666",
  }
  const manejadorOpcionClick = async (event) =>{
    if(mostrarOpcionesUsuario == true){
      setMostrarOpcionesUsuario(false)    

    }else{
      setMostrarOpcionesUsuario(true)    

    }
  }
  const manejadorCerrarSesionClick= async () =>{
      setestaProcesando(true)
      setHayError(false);
      const respuesta = await cerrarSesion()
      setestaProcesando(false)
      if(respuesta.SMListaErrores.length !== 0){
        setHayError(true)
        seterrores(respuesta)
      }else{
        setHayError(false)
        navigate('/')
      }
  }
  const menuOpcionesUsuariostyle={
    display: mostrarOpcionesUsuario ? "flex":"none",
    alignItems: "center",
    gridArea: esComputadora ? '2 / 5 / 3 / 7' : '2 / 5 / 3 / 7',
    width: "80%",
    height: "100%",
    backgroundColor: "#DCAD2F",
    zIndex: "10",
    boxSizing: "border-box",
    position: "relative",
    top: "-10px",
    right: "-28%",
    border: "1px solid #BBBBBB",
    borderRadius: "5px 0px 5px 5px",
    ...(isHovered && {cursor: 'pointer',})
  }
  const cerrarSesionStyle={
    
    fontWeight: '500',
    fontSize: esComputadora ? '1vw' : '2vw',
    //backgroundColor: "red",
    color: estaProcesando ? "#DDDDDD":"black",
    padding: '5%',
    lineHeight: '0.9',
    textAlign: "left",
    width: "100%",

    //borderTop: "1px solid #767676", /* Borde superior de 2px de grosor y color #333 */
    //borderBottom: "1px solid #767676",
  }
  return (
    <>
      <div id='contenedorBarraSuperior' style={contenedorBarraSuperiorStyle}>
        <div id='contenidoLogo' style={cotenidoLogostyle}>
          <img src={LogoSanMiguel} alt='Logo' style={iconoLogoSanMiguelStyle} />
        </div>
        <div id='contenidoNotificacion' style={contenidoNotificacionstyle}>
          <img src={notificacion} alt='Logo' style={icononotificacionstyle} />
        </div>
        <div id='cotenidoInfoUsuario' style={cotenidoInfoUsuariostyle}>
          <p style={nombreStyle}>{(obtenerCookie("nombreUsuario")+' '+obtenerCookie("apellidoPaterno")).toUpperCase()} - {obtenerCookie("codUsuario")}</p>
          <p style={rolStyle}>{obtenerCookie("descRol")}</p>
        </div>
        <div id='contenidoFotoUsuario' style={contenidoFotoUsuariostyle}>
          <div id="fondoAuxFoto" style={fondoAuxFotostyle}>
            <img src={usuario} alt='Logo' style={iconosusuariotyle} />
          </div>
        </div>
        <div
          id='contenidoOpcionesUsuario'
          style={contenidoOpcionesUsuariostyle}
          onMouseEnter={event => {
            setIsHovered(true)
            setIdHovered(event.target.id)
          }}
          onMouseLeave={() => {
            setIsHovered(false)
            setIdHovered('')
          }}
          onClick={manejadorOpcionClick}
        >
          <img src={flechaOpciones} alt='Logo' style={iconoflechaOpcionesstyle} />
        </div>
        <div id="menuOpcionesUsuario" style={menuOpcionesUsuariostyle}>
          
          <div style={cerrarSesionStyle}
          onMouseEnter={event => {
            setIsHovered(true)
            setIdHovered(event.target.id)
          }}
          onMouseLeave={() => {
            setIsHovered(false)
            setIdHovered('')
          }}
          onClick={estaProcesando ? null:manejadorCerrarSesionClick}
          >Cerrar Sesión
          {estaProcesando && <div className="cargando" style={{display:"inline-block", width: "15px", height: "15px", marginLeft: "5px", position: "relative", top:"3px"}}></div>}
          </div>
          
        </div>
      </div>
    </>
  )
}

export default BarraSuperior
