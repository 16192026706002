import React, { useState, useEffect, useRef } from 'react'
import * as estilos from './ModalGenerarPagoPedidoCSS'
import Boton from '../../../../Elementos/Boton'
import Input from '../../../../Elementos/Input'
import {
  Page,
  Text,
  View,
  Document,
  PDFViewer
} from '@react-pdf/renderer'

import { 
  hexToRGBA,
  registroPago
 } from '../../../../Api/Api'

function ModalGenerarPagoPedido (datos) {
  //const [verPdf2, setVerPdf] = useState(datos.verpdf)
  const [estaProcesando, setestaProcesando] = useState(false)
  const [rangoFechas, setrangoFechas] = useState(datos.rangoFechas)
  const [asistenciaDetalleData, setAsistenciaDetData] = useState(datos.asistenciaDetalleData)
  const [montoPagar,setMontoPagar] = useState('')
  const [fechaDePago, setFechaDePago] = useState('')
  const [envioForm, setEnvioForm] = useState(false)

  useEffect(
    ()=>{
      console.log(asistenciaDetalleData)
    }, []
  )

  return (
    <>
      {console.log(datos.esComputadora)}
      <div
        id='baseModal'
        style={estilos.baseModal(datos.esComputadora)}
      >
        {datos.mostrarModal && (
          <div id='contenedorModal' style={estilos.contenedorModal(datos.esComputadora)}>
            <div style={estilos.botonCerrarModalStyle(datos.esComputadora)}>
              <Boton
                style={{
                  backgroundColor: '#E12121',
                  borderRadius: datos.esComputadora ? '0.5vw' : '1vw',
                  padding: datos.esComputadora ? '0.8vw' : '2vw'
                }}
                textoStyle={{
                  color: 'white',
                  width: '100%',
                  fontSize: datos.esComputadora ? '1vw' : '2.5vw'
                }}
                texto='X'
                tipo='submit'
                tonalidad='20'
               
                estaProcesando={estaProcesando}
                onClick={datos.cerrarPdfModalClick}
                
              />
            </div>
            <div style={estilos.tituloCSS(datos.esComputadora)}>
              Generar Pago
            </div>
            <div id="contenidoModal" style={estilos.contenidoModal(datos.esComputadora)}>
              <div style={estilos.formularioCSS(datos.esComputadora)}>
                <Input
                  style={{
                    ...(envioForm && (montoPagar === 0 || montoPagar === '')
                    ? estilos.estiloVacio(datos.esComputadora, montoPagar)
                    : {})
                  }}
                  textoStyle={
                    {
                      fontSize: datos.esComputadora ? "1vw":"2.5vw"
                    }
                  }
                  moderno='Monto a Pagar' //{prod.canSolicitada !==0 ? prod.canSolicitada:null}
                  tipo='number'
                  ubicacion = "1/2/2/11" 
                  estilos={estilos.estiloVacio(datos.esComputadora,envioForm,montoPagar)}
                  onBlur={()=>{}}
                  onChange={(event)=>{
                    setMontoPagar(event.currentTarget.value)
                  }}
                  id={'montoPago'}
                  valorInicial={''} 
                  maxDigitos="8"
                  esComputadora = {datos.esComputadora}
                />
                <Input
                  style={{
                    margin: datos.esComputadora ? "0 0.2vw 0 0.2vw":"0 1vw 0 1vw",
                    ...(envioForm && (fechaDePago === 0 || fechaDePago === '')
                    ? estilos.estiloVacio(datos.esComputadora, fechaDePago)
                    : {})
                  }}
                  textoStyle={
                    {
                      fontSize: datos.esComputadora ? "1vw":"2.5vw"
                    }
                  }
                  moderno='Fecha de Pago' //{prod.canSolicitada !==0 ? prod.canSolicitada:null}
                  tipo='date'
                  ubicacion = "1/11/2/20" 
                  estilos={estilos.estiloVacio(datos.esComputadora,envioForm,fechaDePago)}
                  onBlur={()=>{}}
                  onChange={(event)=>{
                    setFechaDePago(event.currentTarget.value)
                  }}
                  id={'montoPago'}
                  valorInicial={''} 
                  esComputadora = {datos.esComputadora}

                />
                <Boton
                  style={{
                    backgroundColor: '#2D8B0B',
                    borderRadius: datos.esComputadora ? '0.5vw' : '1vw',
                    padding: datos.esComputadora ? '0.8vw' : '2vw',
                    margin: "1vw 0 1vw 0",
                  }}
                  textoStyle={{
                    color: 'white',
                    width: '100%',
                    fontSize: datos.esComputadora ? '1vw' : '2.5vw'
                  }}
                  texto='Confirmar'
                  ubicacion = "2/2/3/10"
                  tipo='submit'
                  tonalidad='20'
                  
                  estaProcesando={estaProcesando}
                  onClick={async()=>{
                    setEnvioForm(true)
                    if(montoPagar!== '' && fechaDePago !==''){
                      setestaProcesando(true)
                      const respuesta = await registroPago(
                        datos.codigoPedidoEliminar,
                        'S',
                        '',
                        '',
                        '',
                        parseInt(montoPagar),
                        fechaDePago
                      )
                      if(respuesta){
                        console.log(respuesta)
                        if(respuesta.SMListaErrores.length === 0){
                          datos.cerrarPdfModalClick()
                        }
                      }
                      setestaProcesando(false)
                    }
                    
                  }}
                />
                <Boton
                  style={{
                    backgroundColor: '#F52424',
                    borderRadius: datos.esComputadora ? '0.5vw' : '1vw',
                    padding: datos.esComputadora ? '0.8vw' : '2vw',
                    margin: "1vw 0 1vw 0",
                  }}
                  textoStyle={{
                    color: 'white',
                    width: '100%',
                    fontSize: datos.esComputadora ? '1vw' : '2.5vw'
                  }}
                  texto='Cancelar'
                  ubicacion = "2/12/3/20"
                  tipo='submit'
                  tonalidad='20'
                  estaProcesando={estaProcesando}
                  onClick={datos.cerrarPdfModalClick}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ModalGenerarPagoPedido
