import Input from '../../../../../Elementos/Input'
import Boton from '../../../../../Elementos/Boton'
import Opciones from '../../../../../Elementos/Opciones'
import React, { useState, useEffect } from 'react'
import * as estilos from './AdministrarProductosCSS'
import {
  obtenerCategorias,
  obtenerDetalleProducto,
  listaDistribuidores
} from '../../../../../Api/Api'
import * as fg from './../../../../../Api/FuncionesGlobales'

function AgregarEditarProductos (datos) {
  const [categorias, setCategorias] = useState([])
  const [estaProcesando, setestaProcesando] = useState(false)
  const [modal, setModal] = useState(false)

  //detalle de producto
  const [nombreProducto, setNombreProducto] = useState('')
  const [unidadesxPaquete, setUnidadesxPaquete] = useState('')
  const [precioDistribuidor, setPrecioDistribuidor] = useState('')
  const [precioPersonal, setPrecioPersonal] = useState('')
  const [precioPublico, setPrecioPublico] = useState('')
  const [estadoOpcion, setEstadoOpcion] = useState(
    (function () {
      if (datos.tipo === 'editar') {
        if (Object.keys(datos.param['productos']).length !== 1) {
          return 'NULL'
        }
      }
    })()
  )
  const [procesandoDetalle, setProcesandoDetalle] = useState(true)

  //fin detalle de producto
  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const ejecucion = async () => {
      console.log(datos.param)
      const respuesta = await obtenerCategorias({ signal }) // Esta función debe retornar el resultado que necesitas para la validación
      if (respuesta) {
        if (respuesta.SMListaErrores.length === 0) {
          setCategorias(respuesta.categorias)
          if (datos.tipo === 'agregar') {
            setProcesandoDetalle(false)
          }
        }
        if (datos.tipo === 'editar') {
          if (Object.keys(datos.param['productos']).length === 1) {
            const respuesta2 = await obtenerDetalleProducto(
              datos.param['codProducto'],
              datos.param['codUsuario'] ? datos.param['codUsuario'] : '',
              { signal }
            )
            console.log(respuesta2)
            if (respuesta2) {
              setNombreProducto(
                respuesta2.dscProducto === 0 ? '' : respuesta2.dscProducto
              )
              setUnidadesxPaquete(
                respuesta2.unidadesPaquete === 0
                  ? ''
                  : respuesta2.unidadesPaquete
              )
              setPrecioDistribuidor(
                respuesta2.precioDistribuidor === 0
                  ? ''
                  : respuesta2.precioDistribuidor
              )
              setPrecioPersonal(
                datos.param['codUsuario'] === ''
                  ? respuesta2.precioClienteExclusivo === 0
                    ? ''
                    : respuesta2.precioClienteExclusivo
                  : respuesta2.precioDistribuidores === 0
                  ? ''
                  : respuesta2.precioDistribuidores
              )
              setPrecioPublico(
                respuesta2.precioTienda === 0 ? '' : respuesta2.precioTienda
              )
              setEstadoOpcion(respuesta2.activo)
              setProcesandoDetalle(false)
            }
          }
          setProcesandoDetalle(false)
        }
      }
    }
    ejecucion()
    return () => {
      controller.abort()
      console.log('SFWEFQWEFQWEFWEFWEF')
    }
  }, [])

  useEffect(() => {
    console.log('entre a')
    if (categorias.length > 0) {
      const ejecucion = async () => {
        const elementoSeleccionado = document.getElementById('catProductoList')
        elementoSeleccionado.value = datos.param['codCategoria']
        /*if(datos.param["codCategoria"] != 0){
                    elementoSeleccionado.disabled=false;
                }*/
      }
      ejecucion()
    }
  }, [categorias])

  const cambiarEstadoOpcion = event => {
    setEstadoOpcion(event.target.value)
    //console.log(event.target.value);
    datos.cambiarEstadoOpcion(event.target.value)
  }

  const cambiaCampoClick = event => {
    //console.log(event.currentTarget.id)
    datos.cambiaCampoClick(event)
  }
  const cambiarCategoria = event => {
    datos.cambiarCategoria(parseInt(event))
    //console.log(event)
  }
  const mensajes = {
    agregar: 'Agregando producto, espera un momento',
    editar: 'Actualizando información, espera un momento',
    default: 'Cargando información, espera un momento'
  }
  return (
    <>
      <div
        id='contAEprod'
        style={estilos.estilosAgregarProducto(datos.esComputadora)}
      >
        {(procesandoDetalle || datos.procesandoDetalle) && (
          <div style={estilos.contProcesandoDetalle(datos.esComputadora)}>
            <div style={estilos.centrarProcesandoDetalle(datos.esComputadora)}>
              {procesandoDetalle || datos.procesandoDetalle ? (
                <>
                  <estilos.LoadingSpinner
                    dimensiones='50px'
                    spinnercolor='#AD6726' // Color del spinner
                    backgroundcolor='#ccc' // Color de fondo del spinne
                    mensaje={
                      datos.procesandoDetalle
                        ? mensajes[datos.tipo]
                        : mensajes.default
                    }
                  />
                </>
              ) : (
                <div>Contenido cargado</div>
              )}
            </div>
          </div>
        )}
        <form
          id='formularioPersona'
          style={estilos.contAgregarEditarProd(datos.esComputadora)}
        >
          <div style={estilos.tituloStyle(datos.esComputadora)}>
            {(() => {
              if (datos.tipo === 'agregar') return '' //"Agregar Producto(s)"
              else if (datos.tipo === 'editar') return '' //"Editar Producto(s)"
            })()}
          </div>
          <div style={estilos.categoriaAEProdStyle(datos.esComputadora)}>
            {console.log(datos.param['codUsuario'])}
            {console.log(Object.keys(datos.param['productos']).length)}
            {console.log(datos.tipo)}

            {console.log(
              datos.param['codUsuario'] === '' &&
                (Object.keys(datos.param['productos']).length === 1 ||
                  datos.tipo !== 'editar')
                ? 'es falso'
                : 'es true'
            )}
            <Opciones
              deshabilitado={
                datos.param['codUsuario'] === '' &&
                (Object.keys(datos.param['productos']).length === 1 ||
                  datos.tipo !== 'editar')
                  ? false
                  : true
              }
              valorInicial={datos.param['codCategoria'] ? datos.param['codCategoria']  : false}
              id='catProductoList'
              formPersona='entra'
              alto='55px'
              moderno={
                Object.keys(datos.param['productos']).length === 1 ||
                datos.tipo !== 'editar'
                  ? 'Categoría de Producto'
                  : false
              }
              tipo='text'
              fontSize='0.9em'
              opciones={categorias !== null ? categorias : []}
              clave='codCategoria'
              valor={['descCategoria']}
              esComputadora={datos.esComputadora}
              onClick={cambiarCategoria}
              programaOrigen='agregarEditarProductos'
              estilos={{
                backgroundColor:
                  datos.param['codUsuario'] === '' ? '' : '#EFEFEF4D',
                border: '2px solid #bababa'
              }}
              //onChange={cambiaCampoClick}
              //estilos={estiloVacio(docSelec)}
            />
          </div>
          <div style={estilos.nombreAEProdStyle(datos.esComputadora)}>
            <Input
              style={{}}
              textoStyle={{}}
              id='nombres'
              moderno={
                Object.keys(datos.param['productos']).length === 1 ||
                datos.tipo !== 'editar'
                  ? 'Nombre de Producto'
                  : false
              }
              tipo='text'
              deshabilitado={
                datos.param['codUsuario'] === '' &&
                (Object.keys(datos.param['productos']).length === 1 ||
                  datos.tipo !== 'editar')
                  ? false
                  : true
              }
              onChange={cambiaCampoClick}
              valorInicial={nombreProducto !== '' ? nombreProducto : ''}
              esComputadora={datos.esComputadora}
            />
          </div>
          <div style={estilos.unidadPorPaqueteAEStyle(datos.esComputadora)}>
            <Input
              style={{}}
              textoStyle={{}}
              id='unidadesxpaquete'
              moderno='Unid. X Paquete'
              tipo='number'
              maxDigitos={5}
              onChange={cambiaCampoClick}
              valorInicial={unidadesxPaquete !== '' ? unidadesxPaquete : ''}
              deshabilitado={datos.param['codUsuario'] === '' ? false : true}
              esComputadora={datos.esComputadora}
            />
          </div>
          <div style={estilos.precDistribuidorStyle(datos.esComputadora)}>
            <Input
              id='precioDistribuidor'
              moderno={
                datos.param['codUsuario'] !== ''
                  ? 'Precio del distribuidor ' + datos.param['codUsuario']
                  : 'Precio Distribuidor'
              }
              tipo='number'
              autoComplete
              maxDigitos={5}
              onChange={cambiaCampoClick}
              valorInicial={precioDistribuidor !== '' ? precioDistribuidor : ''}
              esComputadora={datos.esComputadora}
            />
          </div>
          <div style={estilos.precPersonalStyle(datos.esComputadora)}>
            <Input
              id='precioPersonal'
              moderno={
                datos.param['codUsuario'] !== ''
                  ? 'Precio del distribuidor'
                  : 'Precio Personal'
              }
              tipo='number'
              autoComplete
              maxDigitos={5}
              onChange={cambiaCampoClick}
              valorInicial={precioPersonal !== '' ? precioPersonal : ''}
              deshabilitado={datos.param['codUsuario'] === '' ? false : true}
              esComputadora={datos.esComputadora}
            />
          </div>
          <div style={estilos.precPublicoStyle(datos.esComputadora)}>
            <Input
              id='precioPublico'
              moderno='Precio Público'
              tipo='number'
              autoComplete
              maxDigitos={5}
              onChange={cambiaCampoClick}
              valorInicial={precioPublico !== '' ? precioPublico : ''}
              deshabilitado={datos.param['codUsuario'] === '' ? false : true}
              esComputadora={datos.esComputadora}
            />
          </div>
          <div
            style={{
              ...estilos.activoStyle(datos.esComputadora),
              backgroundColor:
                datos.param['codUsuario'] === '' ? 'white' : '#CCCCCC'
            }}
          >
            <input
              type='radio'
              id='S'
              name='opciones'
              value='S'
              checked={estadoOpcion === 'S'}
              onChange={cambiarEstadoOpcion}
              disabled={datos.param['codUsuario'] === '' ? false : true}
            />
            <label htmlFor='S'>Habilitado</label>
            <br />
            {/*datos.tipo === "agregar" || (datos.tipo === "editar" && Object.keys(datos.param["productos"]).length === 1) ? <br/>:""*/}
            <input
              type='radio'
              id='N'
              name='opciones'
              value='N'
              checked={estadoOpcion === 'N'}
              onChange={cambiarEstadoOpcion}
              disabled={datos.param['codUsuario'] === '' ? false : true}
            />
            <label htmlFor='N'>Deshabilitado</label>
            <br />
            {datos.tipo === 'editar' &&
            Object.keys(datos.param['productos']).length > 1 ? (
              <>
                <input
                  type='radio'
                  id='NULL'
                  name='opciones'
                  value='NULL'
                  checked={estadoOpcion === 'NULL'}
                  onChange={cambiarEstadoOpcion}
                  disabled={datos.param['codUsuario'] === '' ? false : true}
                />
                <label htmlFor='NULL'>Modificar Precios</label>
              </>
            ) : (
              ''
            )}
          </div>
          <div
            style={{
              gridArea: datos.esComputadora
                ? ' 20 / 2/ 21 / 10'
                : ' 20 / 2/ 21 / 10',
              paddingTop: '1vh'
            }}
          >
            <Boton
              style={{
                backgroundColor: '#2D8B0B',
                borderRadius: datos.esComputadora ? '0.5vw' : '1vw',
                padding: datos.esComputadora ? '0.8vw' : '2vw'
              }}
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: datos.esComputadora ? '1vw' : '2.5vw'
              }}
              texto='Confirmar'
              tipo='submit'
              estaProcesando={estaProcesando}
              onClick={event => {
                event.preventDefault()
                datos.confirmarClick()
              }}
            />
          </div>
          <div
            style={{
              gridArea: datos.esComputadora
                ? ' 20 / 12/ 21 / 20'
                : ' 20 / 12/ 21 / 20',
              paddingTop: '1vh'
            }}
          >
            <Boton
              style={{
                backgroundColor: '#E12121',
                borderRadius: datos.esComputadora ? '0.5vw' : '1vw',
                padding: datos.esComputadora ? '0.8vw' : '2vw'
              }}
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: datos.esComputadora ? '1vw' : '2.5vw'
              }}
              texto='Cancelar'
              tipo='submit'
              color={fg.hex_rgb('#E12121', 1)}
              tonalidad='20'
              onClick={event => {
                event.preventDefault()
                datos.cerrarModal()
              }}
            />
          </div>
        </form>
      </div>
    </>
  )
}

export default AgregarEditarProductos
