import styled from 'styled-components';
import { keyframes } from 'styled-components';

export const opcionDistCabecera = (esComputadora)=>({
  gridArea: esComputadora ? '1 / 1 / 21 / 21' : '1 / 1 / 2 / 21',
})
export const contenedorPrincipalListaStyle = (esComputadora) =>({
    marginTop: esComputadora ? "0.5vw":"1vw",
    marginBottom: esComputadora ? "0.5vw":"1vw",
    display: 'block',
    gridArea: esComputadora ? '3 / 1 / 19 / 21' : '2 / 1 / 19 / 21',
    width: "100%",
    //height: esComputadora ? "65vh":"53vh",
});
export const procesandoProductosStyle = (esComputadora) =>({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gridArea: esComputadora ? '1 / 1 / 21 / 21' : '1 / 1 / 20 / 21',
  width: "100%",
  height: esComputadora ? "70vh":"60vh",
  zIndex: "2"
});
export const contenedorLista = (esComputadora) =>({
    //backgroundColor: hexToRGBA("#EEEEEE",1),    
    display: 'grid',
    gridTemplateRows: 'repeat(1, 1fr)',
    gridTemplateColumns: 'repeat(1, 1fr)',
    width: "100%",
    height: "100%",
    borderRadius: "2vw",
    overflow: "auto",

 });

export const contenidoStyle=(esComputadora) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
});

export const cardCategoria = (esComputadora,activo) =>({
    backgroundColor: activo=='S' ?'white':"#ffd9d9", //"#FCFCFC",
    display: 'block',
    //clear: "both",
    borderRadius: '1vw',
    //margin: '20px 0 0 0',
    //padding: "10px",
    overflow: esComputadora ? 'auto' : 'none',
    height: esComputadora ? 'auto' : 'auto',
    boxSizing: 'border-box',
    width: esComputadora ? '100%' : '83vw',
    border: '1px solid #ccc',
    boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
    marginTop: "10px"

  });
  export const nomCatProdStyle = (esComputadora,activo) => ({
    display: 'grid',
    gridTemplateRows: "100%",
    gridTemplateColumns: "10% 80% 10%",
    backgroundColor: activo === "S" ? '#AD6726':"#ababab",
    color: 'white',
    fontSize: esComputadora ? '1vw' : '1.7vh',
    //color: "#363636",
    fontWeight: 'bolder',
    //marginBottom: "5px",
    textAlign: 'Center',
    borderRadius: '1vw 1vw 0px 0px',
    padding: '5px',
  });

  export const contenidoProductosCategoriaStyle = (esComputadora) => ({
    overflow: "auto",
    height: esComputadora ? 'auto' : 'auto',
    
  })
  export const contIntCat = (esComputadora) => ({
    //overflow: "auto",
    height: esComputadora ? 'auto' : 'auto',
    //backgroundColor: hexToRGBA("#B98787",0.25),
    //paddingLeft: "10px",
   // zIndex: "80"
    width: esComputadora ? "":"200%"
  })

  export const cabProductosStyle= (esComputadora) => ({
    color: "black",
    display: 'grid',
    gridTemplateColumns: esComputadora ? '5% 20% repeat(7, calc(75%/7))':'5% 15% repeat(7, calc(80%/7))',
    //gridTemplateColumns: '10% 25% repeat(7,1fr)',
    gridTemplateRows: '100%',
    marginTop: '5px',
    fontSize: esComputadora ? '0.9vw' : '1.6vh',
    fontWeight: "900",
    textAlign: "center",
  });

  export const contProductoStyle = (esComputadora,activo)=>({
    backgroundColor: activo === "S" ? "white":"#ffd9d9",
    display: 'grid',
    gridTemplateColumns: esComputadora ? '5% 20% repeat(7, calc(75%/7))':'5% 15% repeat(7, calc(80%/7))',
    gridTemplateRows: '100%',
    marginTop: '3px',
    fontSize: esComputadora ? '0.9vw' : '1.6vh',
    color: '#868686',
    textAlign: "center",
    paddingLeft:"20px",
});

export const contenedorCheckboxStyle = (esComputadora)=>({
  gridArea: "1/1/2/2",
  //overflow: "auto",
  textAlign: "left",
  marginRight: esComputadora ? "": "10px",
  display: "flex",
  justifyContent: "center", alignItems: "center",
  
});
export const nombreProductoStyle = (esComputadora)=>({
  gridArea: "1/2/2/3",
  overflow: "auto",
  textAlign: "left",
  marginRight: esComputadora ? "": "10px",
  display: "flex",
  justifyContent: "left", alignItems: "center",
  fontSize: esComputadora ? "1vw":"2.3vw",

});

export const unidadPaqueteStyle = (esComputadora)=>({
  gridArea: "1/3/2/4",
  marginRight: esComputadora ? "": "10px",
  display: "flex",
  justifyContent: "center", alignItems: "center",
  fontSize: esComputadora ? "1vw":"2.3vw",

});
export const precioDistribuidorStyle = (esComputadora)=>({
  gridArea: "1/4/2/5",
  marginRight: esComputadora ? "": "10px",
  display: "flex",
  justifyContent: "center", alignItems: "center",
  fontSize: esComputadora ? "1vw":"2.3vw",

});
export const precioEmpleadosStyle = (esComputadora)=>({
  gridArea: "1/5/2/6",
  marginRight: esComputadora ? "": "10px",
  display: "flex",
  justifyContent: "center", alignItems: "center",
  fontSize: esComputadora ? "1vw":"2.3vw",

});
export const precioPublicoStyle = (esComputadora)=>({
  gridArea: "1/6/2/7",
  marginRight: esComputadora ? "": "10px",
  display: "flex",
  justifyContent: "center", alignItems: "center",
  fontSize: esComputadora ? "1vw":"2.3vw",

});
export const stockActualStyle = (esComputadora)=>({
  gridArea: "1/7/2/8",
  marginRight: esComputadora ? "": "10px",
  display: "flex",
  justifyContent: "center", alignItems: "center",
  fontSize: esComputadora ? "1vw":"2.3vw",

});
export const contenedorBotonEditarStyle = (esComputadora)=>({
  gridArea: "1/8/2/9",
  marginRight: esComputadora ? "": "10px",
  display: "flex",
  justifyContent: "center", alignItems: "center",
  
});
export const contenedorBotonEliminarStyle = (esComputadora)=>({
  gridArea: "1/9/2/10",
  marginRight: esComputadora ? "": "10px",
  display: "flex",
  justifyContent: "center", alignItems: "center",
});

export const botonEditarStyle= (esComputadora) => ({
  border: "none",
  backgroundColor: "transparent",
  cursor: "pointer",
  filter: "drop-shadow(0.5px 1px 2px rgba(1, 1, 1, 0.5))",

});
export const botonEliminarStyle= (esComputadora) => ({
  border: "none",
  backgroundColor: "transparent",
  cursor: "pointer",
  filter: "drop-shadow(0.5px 0.5px 0.5px rgba(1, 1, 1, 0.9))",
});

export const imagenBotonEditarStyle= (esComputadora) => ({
  width: esComputadora ? '1.5vw':'2.3vh', 
  height: 'auto'
  
});
export const imagenBotonEliminarStyle= (esComputadora) => ({
  width: esComputadora ? '1.5vw':'2.6vh', 
  height: 'auto',
  
  
});
export const imagenBotonAgregarStyle= (esComputadora) => ({
  width: esComputadora ? '2vw':'4vh', 
  height: 'auto',
  //backgroundColor: "rgb(210, 116, 244)",
  //filter: "",
  borderRadius: "1vw",
  cursor: "pointer",
  filter: "invert(100%) drop-shadow(1px 1px 0.5px rgba(1, 1, 1, 0.5))",
});

export const checkboxStyle = (esComputadora)=>({
  //gridArea: "1/1/2/2",
  //overflow: "auto",
  width: esComputadora ? '1.5vw':'2.3vh', 
});

export const modalStyle= (esComputadora) =>({
  position: "absolute",
  left: "0px",
  top: "0px",
  width: "100vw",
  height: "100vh",
  backgroundColor: hexToRGBA("#C1C1C1",0.8),
  zIndex: "20", 
  boxSizing: "border-box"

});

export const contContenidoModalStyle=(esComputadora) => ({
  position: "relative",
  width: "90vw",
  height: "90vh",
  left: "5vw",
  boxSizing: "border-box",
  top: "5vh",
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
export const contenidoModalStyle=(esComputadora,modo) =>({
  display: 'grid',
  gridTemplateRows: 'repeat(20, 1fr)',
  gridTemplateColumns: 'repeat(20, 1fr)',
  //width: modo=="eliminar" ? esComputadora ? "30vw":"60vw":"80vw",
  width: (() => {
   
   
  })(),
  height: modo=="eliminar" ? "40vh":"70vh",
  overflow: "auto",
  backgroundColor: "white",
  boxSizing: "border-box",
  borderRadius: "1vw",
  padding: "10px"
});

export const botonCerrarModalStyle=(esComputadora) =>({
  display: "flex",
  gridArea: esComputadora ? ' 1 / 18 / 2 / 21':' 1 / 18 / 2 / 21',
  justifySelf: "right",
  alignItems: "center",
});

export const agregarProductoStyle=(esComputadora)=>({
  gridArea: esComputadora ? ' 3 / 1/ 21 / 21':' 3 / 1/ 21 / 21',
  display: "grid",
  gridTemplateRows : "repeat(1,1fr)",
  gridTemplateColumns: "repeat(1,1fr)",
  //backgroundColor: "skyblue",
})
export const editarProductoStyle=(esComputadora)=>({
  gridArea: esComputadora ? ' 2 / 1/ 21 / 21':' 2 / 1/ 21 / 21',
  //backgroundColor: "green",
  display: "grid",
  gridTemplateRows : "repeat(15,1fr)",
  gridTemplateColumns: "repeat(20,1fr)",
  fontSize: esComputadora ? "1vw":"2.5vw",
})
export const eliminarProductoStyle=(esComputadora)=>({
  gridArea: esComputadora ? ' 2 / 1/ 21 / 21':' 2 / 1/ 21 / 21',
  //backgroundColor: "red",
  display: "grid",
  gridTemplateColumns : "repeat(10,1fr)",
  gridTemplateRows : "repeat(10,1fr)",
  fontSize: esComputadora ? "1vw":"2.5vw",
});

export const opcionesSeleccionados = (esComputadora) =>({
  backgroundColor: hexToRGBA("#ad6726",0.2),
  borderRadius: "1vw",
  width: "100%",
  //height: "8vh",
  gridArea: "19/1/21/21",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  
});

export const opcion = (esComputadora) => ({
  marginRight: "5vw",
})
export const botonOpcion = (esComputadora) =>({
  backgroundColor: hexToRGBA("#ad6726",1),
  border: "0px solid #000",
  borderRadius: "1vw",
  padding: esComputadora ? "1vw":"2vw",
  color: "white",
  fontSize: esComputadora ? "1vw":"2.3vw",
  cursor: "pointer",
  
});

export const ScrollableContainer = styled.div`
  width: 100%;
  background-color: #f0f0f0; /* Fondo del contenedor */
  overflow: auto;
  height: 100%;
  border-radius: 8px 8px 8px 8px; /* Bordes redondeados */
  padding: 0px 10px 11px 0px; /* Espaciado interno */
  border-top: 0.1px solid #d9d9d9;
  border-bottom: 0.1px solid #d9d9d9;
  border-left: 0.1px solid #d9d9d9;
  border-right: 0.1px solid #d9d9d9;
  box-sizing: border-box;
  /* Webkit browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 10px; /* Ancho del scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: #e0e0e0; /* Fondo del track */
    border-radius: 8px; /* Bordes redondeados del track */
  }

  ::-webkit-scrollbar-thumb {
    background: #009688; /* Color del thumb */
    border-radius: 8px; /* Bordes redondeados del thumb */
    border: 2px solid #00796b; /* Borde del thumb */
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #00796b; /* Color del thumb al pasar el ratón */
  }

  /* Firefox */
  scrollbar-width: thin; /* Ancho del scrollbar */
  scrollbar-color: #009688 #e0e0e0; /* Color del thumb y del track */
`;

export const estilosAgregarProducto= (esComputadora) =>({
  //background: "skyblue",
  display: 'grid',
  gridTemplateRows: 'repeat(20, 1fr)',
  gridTemplateColumns: 'repeat(20, 1fr)',
  overflow: "auto",
  boxSizing: "border-box",
  borderRadius: "1vw",
  width:"100%"
  //padding: "10px"
});
export const contAgregarEditarProd=(esComputadora)=>({
    //marginTop: "30px",
    display: "grid",
    gridTemplateColumns: "repeat(20,1fr)",
    gridTemplateRows: "repeat(20,1fr)",
    gridArea: esComputadora ? "1/1/21/21":"1/1/21/21",
    backgroundColor: "#EEEEEE",
    //background: esComputadora ? "radial-gradient(circle at 57.52% 55.27%, #fac44d 0, #f2b53c 50%, #eaa62b 100%)":"",
    border: esComputadora ? "1px solid #ccc":"1px solid #ccc",
    borderRadius: esComputadora ? "1vw":"1vw",
    boxShadow: esComputadora ? '2px 2px 4px rgba(0, 0, 0, 0.2)':"",
    padding: esComputadora ? "30px":"10px",
    boxSizing: "border-box",
    width: "100%",
    height: "93%",
    overflow: "auto",
});
export const contProcesandoDetalle=(esComputadora)=>({
  //marginTop: "30px",
  zIndex: "1",
  display: "grid",
  gridTemplateColumns: "repeat(20,1fr)",
  gridTemplateRows: "repeat(20,1fr)",
  gridArea: esComputadora ? "1/1/21/21":"1/1/21/21",
  backgroundColor: "#EEEEEE",
  //background: esComputadora ? "radial-gradient(circle at 57.52% 55.27%, #fac44d 0, #f2b53c 50%, #eaa62b 100%)":"",
  border: esComputadora ? "1px solid #ccc":"1px solid #ccc",
  borderRadius: esComputadora ? "1vw":"1vw",
  boxShadow: esComputadora ? '2px 2px 4px rgba(0, 0, 0, 0.2)':"",
  padding: esComputadora ? "30px":"10px",
  boxSizing: "border-box",
  width: "100%",
  height: "93%",
  overflow: "auto",
});
export const centrarProcesandoDetalle=(esComputadora)=>({
  gridArea: esComputadora ? "1/1/21/21":"1/1/21/21",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex:"5"
});
export const tituloStyle=(esComputadora)=>({
  display: "flex",
  justifyContent: "center", /* Centra horizontalmente */
  alignItems: "center", /* Centra verticalmente */
  gridArea:"1/1/2/21",
})
export const categoriaAEProdStyle = (esComputadora)=>({
  ...separacionInputs(esComputadora),
  gridArea: "2/1/3/21",
  width: esComputadora ? "100%":"100%",
})
export const nombreAEProdStyle = (esComputadora)=>({
  ...separacionInputs(esComputadora),
  gridArea: "3/1/4/11",
  width: esComputadora ? "100%":"100%",
})
export const unidadPorPaqueteAEStyle=(esComputadora)=>({
  ...separacionInputs(esComputadora),
  gridArea: "3/11/4/21",
  width: esComputadora ? "100%":"100%",
})
export const precDistribuidorStyle=(esComputadora)=>({
  ...separacionInputs(esComputadora),
  gridArea: "4/1/5/11",
  width: esComputadora ? "100%":"100%",
})
export const precPersonalStyle=(esComputadora)=>({
  ...separacionInputs(esComputadora),
  gridArea: "4/11/5/21",
  width: esComputadora ? "100%":"100%",
})
export const precPublicoStyle=(esComputadora)=>({
  ...separacionInputs(esComputadora),
  gridArea: "5/1/6/11",
  width: esComputadora ? "100%":"100%",
})
export const activoStyle=(esComputadora)=>({
  ...separacionInputs(esComputadora),
  //backgroundColor:"blue",
  gridArea: "5/11/6/21",
  width: esComputadora ? "100%":"100%",
  fontSize: esComputadora ? '1vw' : '2.5vw',
  backgroundColor:"white",
  boxSizing:"border-box",
  height: esComputadora ? "6vw":"20vw",
  border: esComputadora ? "0.4vw solid #ccc":"0.4vw solid #ccc",
  borderRadius: "1vw"
})
export const grupoButtonStyle=(esComputadora)=>({
  backgroundColor:"red",
})
function hexToRGBA (hex, opacidad) {
    hex = hex.replace('#', '')
    const r = parseInt(hex.substring(0, 2), 16)
    const g = parseInt(hex.substring(2, 4), 16)
    const b = parseInt(hex.substring(4, 6), 16)
  
    return `rgba(${r}, ${g}, ${b}, ${opacidad})`
}

// Definir la animación de rotación
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Crear el contenedor del spinner, usando flexbox para alinear verticalmente el spinner y el mensaje
const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column; /* Alinea el spinner y el mensaje en una columna */
  justify-content: center; /* Centra el contenido verticalmente */
  align-items: center; /* Centra el contenido horizontalmente */
  height: 100%; /* Ocupa toda la altura de la ventana */
  text-align: center; /* Centra el texto horizontalmente */
`;

// Crear el spinner con colores personalizados usando transient props
const Spinner = styled.div`
  box-sizing: border-box;
  border: 7px solid ${props => props.$backgroundcolor || '#d3d3d3'}; /* Color del fondo del spinner */
  border-top: 7px solid ${props => props.$spinnercolor || '#00f'}; /* Color del spinner en movimiento */
  border-radius: 50%;
  width: ${props => props.$dimensiones || '#00f'};
  height: ${props => props.$dimensiones || '#00f'};
  animation: ${spin} 1s linear infinite;
  margin-bottom: 16px; /* Espacio entre el spinner y el mensaje */
`;

export const LoadingSpinner = ({ 
  dimensiones = '50px',
  spinnercolor = '#00f', 
  backgroundcolor = '#d3d3d3', 
  mensaje = 'Cargando información, espera un momento' 
}) => {
  return (
    <SpinnerContainer id="contenedorProcesoDetalle">
      <Spinner 
        id='loadingProcesando' 
        $spinnercolor={spinnercolor} 
        $backgroundcolor={backgroundcolor} 
        $dimensiones={dimensiones}
      />
      <div>{mensaje}</div>
    </SpinnerContainer>
  );
};

const separacionInputs = esComputadora => ({
  boxSizing: 'border-box',
  padding: esComputadora ? '0.2vw' : '0.5vw'
})
