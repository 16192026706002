import React, { useState, useEffect, useRef } from 'react'
import * as estilos from './ModalPedidosCSS'
import Boton from '../../../../Elementos/Boton'
import {
  Page,
  Text,
  View,
  Document,
  PDFViewer
} from '@react-pdf/renderer'

import { 
  hexToRGBA,
  formatearFecha2,
  capitalizeWords,
  formatearFecha3,
  formatearMonedaSoles,
  eliminarPedido
 } from './../../../../Api/Api'

function ModalPedidos (datos) {
  //const [verPdf2, setVerPdf] = useState(datos.verpdf)
  const [estaProcesando, setestaProcesando] = useState(false)
  const [rangoFechas, setrangoFechas] = useState(datos.rangoFechas)
  const [asistenciaDetalleData, setAsistenciaDetData] = useState(datos.asistenciaDetalleData)

  useEffect(
    ()=>{
      console.log(asistenciaDetalleData)
    }, []
  )

  return (
    <>
      {console.log(datos.esComputadora)}
      <div
        id='baseModal'
        style={estilos.baseModal(datos.esComputadora)}
      >
        {datos.mostrarModal && (
          <div id='contenedorModal' style={estilos.contenedorModal(datos.esComputadora)}>
            
            <div style={estilos.botonCerrarModalStyle(datos.esComputadora)}>
              <Boton
                style={{
                  backgroundColor: '#E12121',
                  borderRadius: datos.esComputadora ? '0.5vw' : '1vw',
                  padding: datos.esComputadora ? '0.4vw' : '1vw'
                }}
                textoStyle={{
                  color: 'white',
                  width: '100%',
                  fontSize: datos.esComputadora ? '1vw' : '2.5vw'
                }}
                texto='X'
                tipo='submit'
                
                tonalidad='20'
                
                estaProcesando={estaProcesando}
                onClick={datos.cerrarPdfModalClick}
                
              />
            </div>
            <div id="contenidoModal" style={estilos.contenidoModal(datos.esComputadora)}>
                <div style={{ 
                    gridArea: datos.esComputadora ? '2/2/3/10':'2/2/3/10',
                    //fontSize: datos.esComputadora ? "1vw":"2.5vw"
                    }}>
                    ¿Seguro que deseas eliminar el pedido?
                </div>
                
                <div style={{ 
                    gridArea: datos.esComputadora ? '3/2/4/10':'3/2/4/10', 
                    fontWeight: "bolder",
                    //fontSize: datos.esComputadora ? "1vw":"2.5vw"
                    }}>
                    Nro. {String(datos.codigoPedidoEliminar).padStart(8, '0')}
                </div>
                <div style={{ gridArea: datos.esComputadora ? ' 10/2/11/5':' 10/2/11/5',}}>
                    <Boton
                        style={{
                          backgroundColor: '#2D8B0B',
                          borderRadius: datos.esComputadora ? '0.5vw' : '1vw',
                          padding: datos.esComputadora ? '0.4vw' : '1vw'
                        }}
                        textoStyle={{
                          color: 'white',
                          width: '100%',
                          fontSize: datos.esComputadora ? '1vw' : '2.5vw'
                        }}
                        texto='Confirmar'
                        tipo='submit'
                       
                        tonalidad='20'
                       
                        estaProcesando={estaProcesando}
                        onClick={async(event)=>{
                            event.preventDefault();
                            setestaProcesando(true);
                            const respuesta = await eliminarPedido(datos.codigoPedidoEliminar)
                            if (respuesta){
                                console.log(respuesta)
                            }
                            datos.cerrarPdfModalClick();
                            setestaProcesando(false);
                        }}
                        
                    />
                </div>
                <div style={{  gridArea: datos.esComputadora ? ' 10 / 7/ 11 / 10':' 10 / 7/ 11 / 10',marginBottom: "1vh"}}>
                    <Boton
                        style={{
                          backgroundColor: '#E12121',
                          borderRadius: datos.esComputadora ? '0.5vw' : '1vw',
                          padding: datos.esComputadora ? '0.4vw' : '1vw'
                        }}
                        textoStyle={{
                          color: 'white',
                          width: '100%',
                          fontSize: datos.esComputadora ? '1vw' : '2.5vw'
                        }}
                        texto='Cancelar'
                        tipo='submit'
                        tonalidad='20'
                        estaProcesando={estaProcesando}
                        onClick={(event)=>{
                            event.preventDefault();
                            datos.cerrarPdfModalClick();
                        }}
                    />
                </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ModalPedidos
